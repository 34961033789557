import React from 'react'
import { Link as RouterLink } from '@reach/router'
import {
  Box,
  Card,
  Heading,
  Text,
  Flex
} from 'rebass'

import ImageSlider from './slider'


function BackCard() {
  return (
    <>
      {/* Back of card */}
      <Card bg="secondary" maxWidth={600} p={3} m="auto" color="white" sx={{
        borderRadius: 5
      }} >
      <Heading
       sx={{
         textAlign: "center",
         margin: 2,
         fontSize: 30
        }}
        >Happy Sixtieth Birthday Mom</Heading>

        <Text as="h2" sx={{
          textAlign: "center"
        }}>
          Enjoy these gifts from your youngest!
        </Text>

        <ImageSlider images={['/a.jpg', '/b.jpg', '/c.jpg', 'd.jpg']} />

        <Flex textAlign="center">
          <Box
            as={RouterLink}
            to='/gift1'           
            mx={4}
            p={3}
            width={1/2}
            color='white'
            bg='primary'
            sx={{
              borderRadius: 10
            }
            }
            >
            Gift 1
          </Box>
          <Box
            as={RouterLink}
            to='/gift2'            
            mx={4}
            p={3}
            width={1/2}
            color='white'
            bg='primary'
            sx={{
              borderRadius: 10
            }
            }
            >
            Gift 2
          </Box>
        </Flex>
      </Card>
    </>
  )
}
export default BackCard