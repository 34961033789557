import React from 'react'
import { Link as RouterLink } from '@reach/router'

import { Flex, Box, Link } from 'rebass'

function Navbar() {
  return (
    <>
      <Flex
      px={2}
      alignItems="space-between"
      >
        <Link as={RouterLink} to="/" textAlign="center" fontSize={3} variant='nav'>
          Front of Card
        </Link>
        <Link textAlign="center" fontSize={3} variant='nav' as={RouterLink} to="/back">
          Back of Card
        </Link>
        <Box mx='auto' />
        <Link textAlign="center" fontSize={3} variant='nav' as={RouterLink} to="/gift1">
          Gift 1
        </Link>
        <Link textAlign="center" fontSize={3} variant='nav' as={RouterLink} to="/gift2">
          Gift 2
        </Link>
      </Flex>
    </>
  )
}
export default Navbar