import React from 'react'
import Navbar from './navbar'


import {
  Box,
  Card,
  Image,
  Link,
  Flex
} from 'rebass'

function Gift1() {
  return (
    <>
    <Navbar />
        <Box fontSize={5} mb={3} textAlign="center" mx="auto" p={3} width={1/2}
        >
            I got you Three Orchids of Your Choice!
        </Box>
  
      <Flex mx={3} flexWrap='wrap' >
        <Card mx="auto" my={4} width={[1, 1/4]}  p={3} color="white" sx={{
          borderRadius: 10
        }}>
          <Image src="/orchid1.png" my={16} mx="auto" width="300" height="400" display="block"/>
        </Card>
        <Card mx="auto" my={4} width={[1, 1/4]}  p={3} color="white" sx={{
          borderRadius: 10
        }}>
          <Image src="/orchid2.png" my={16} mx="auto" width="300" display="block"/>
        </Card>
        <Card mx="auto" my={4} width={[1, 1/4]}  p={3} color="white" sx={{
          borderRadius: 10
        }}>
          <Image src="/orchid3.png" my={16} mx="auto" width="300" display="block"/>
        </Card>
      </Flex>

      <Box fontSize={5} mt={4} textAlign="center" mx="auto" p={3} width={1/2}
        >
            One orchid to represent each child you have!
        </Box>

        <Box fontSize={3} textAlign="center" mx="auto" p={3} width={1}
        >
          Head over to the <Link href="https://njorchids.org/annual-show/2020-north-jersey-orchid-show-and-sale/">2020 Orchid Show</Link> hosted at Rutgers University to pick them up from <Link href="https://tenshinorchids.com">Ten Shin Gardens</Link>! Your car is already <Link href="/parking.png">registered</Link>!
        </Box>

    </>
  )
}
export default Gift1