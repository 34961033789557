import React from 'react'
import ImageSlider from './slider'

import { Link as RouterLink } from '@reach/router'

import {
  Card,
  Heading,
  Text,
  Button,
} from 'rebass'


function FrontCard() {
  return (
    <>
      <Card bg="primary"  minHeight={600} width={[1, 1/2]} p={5} m="auto" color="white" sx={{
        borderRadius: 5
      }} >
      <Heading
       sx={{
         textAlign: "center",
         margin: 2,
         fontSize: 30
        }}
        >Happy Sixtieth Birthday Mom</Heading>

        <ImageSlider images={['/1.jpg', '/2.jpg', '/3.jpg', '4.jpg']} />

        <Text as="h2" sx={{
          textAlign: "center",
          marginTop: '16px'
        }}>
          I hope you have a wonderful birthday!
        </Text>
        <Button m={16} fontSize={24} width={1/2} as={RouterLink} to='/back' m="auto" display="block" variant='secondary' my={2}>Flip Card</Button>
      </Card>

    </>
  )
}
export default FrontCard