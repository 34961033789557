import React from 'react'
import {
  Image
} from 'rebass'

function ImageSlider({ images }) {
  return (
    <>
      <Image src={images[0]} my={16} mx="auto" width="300" height="400" display="block"/>
    </>
  )
}
export default ImageSlider