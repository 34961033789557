import React from 'react'
import Navbar from './navbar'
import { Box, Flex, Link, Image, Button } from 'rebass'

function Gift2() {
  return (
    <>
      <Navbar />
      <Box fontSize={5} textAlign="center" mx="auto" p={3} width={1/2}
        >
            A Compassionate Approach by Tim Van Orden
        </Box>
      <Box mb={3}>

          <Image height={400} src="https://i0.wp.com/timvanorden.com/wp-content/uploads/2017/06/eBook-Cover-12-08-16.jpg" my={16} mx="auto" width="300" display="block"  sx={{
            borderRadius: 10
          }}/>
      </Box>
      <Flex maxWidth={600} mx="auto" my={3} alignItems="space-between">
        <Button bg="secondary" mx={4} width={1/2}>
          <Link variant="nav" href="https://mega.nz/#!DmZ1mCqK!1sU4gxtQ01BKRh9VmVfSnp1Eb88UCiXS1yPog7GbwhU">PDF</Link>
        </Button>
        <Box mx='auto' />
        <Button bg="secondary" mx={4} width={1/2}>
        <Link variant="nav" href="https://mega.nz/#!bnIn2axI!VKOaFwpRFMLZJxKqoIo1eSUpDSzjtjANypSKXKLir_w">Audio*</Link>
        </Button>
      </Flex>
      <Box fontSize={3} textAlign="center" mx="auto" p={3} width={1}
        > 
          * For the audio book <Link href="https://play.google.com/store/apps/details?id=ak.alizandro.smartaudiobookplayer&hl=en">use this app</Link> on your phone to listen to it!
        </Box>
 
    </>
  )
}
export default Gift2