import React from 'react'
import { Router } from '@reach/router'
import Theme from './theme'
import FrontCard from './front-card'
import BackCard from './back-card'
import Gift1 from './gift1'
import Gift2 from './gift2'
import 'styles/font.css'

import { Box } from 'rebass'


function App() {
  return (
    <Theme>
      <Box my={1}>
      <Router>
        <FrontCard path="/" />
        <BackCard path="/back"/>
        <Gift1 path="/gift1" />
        <Gift2 path="/gift2" />
      </Router>
      </Box>
    </Theme>
  )
}

export default App

